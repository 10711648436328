<template>
  <b-card
    class="bg-transparent shadow-none swiper-slides mt-3"
  >
    <swiper
      class="swiper-centered-slides p-1"
      :options="swiperOptions"
    >

      <!-- slides -->
      <swiper-slide
        v-for="data in swiperData"
        :key="data.text"
        class="rounded swiper-shadow"
      >
        <template v-if="data.custom_icon">
          <template v-if="data.custom_icon_name == 'Plant'">
            <h1>
              <Plant />
            </h1>
          </template>
          <template v-else-if="data.custom_icon_name == 'ShoppingCartDiscount'">
            <h1>
              <ShoppingCartDiscount />
            </h1>
          </template>
          <template v-else-if="data.custom_icon_name == 'Recycle'">
            <h1>
              <Recycle />
            </h1>
          </template>
          <template v-else-if="data.custom_icon_name == 'Share'">
            <h1>
              <Share />
            </h1>
          </template>
          <template v-else-if="data.custom_icon_name == 'Clock'">
            <h1>
              <Clock />
            </h1>
          </template>
        </template>

        <template v-else>
          <feather-icon
            :icon="data.icon"
            size="28"
          />
        </template>

        <div class="swiper-text pt-md-1 pt-sm-50">
          {{ data.text }}
        </div>
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  Share,
  Plant,
  ShoppingCartDiscount,
  Recycle,
  Clock,
} from 'vue-icon-packs/ti'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCard,
    Share,
    Plant,
    ShoppingCartDiscount,
    Recycle,
    Clock,
  },
  data() {
    return {
      swiperData: [
        { custom_icon: true, custom_icon_name: 'Share', text: this.$t('idy.homepage.swiperSlider.firstTitle') },
        { custom_icon: true, custom_icon_name: 'ShoppingCartDiscount', text: this.$t('idy.homepage.swiperSlider.secondTitle') },
        { custom_icon: true, custom_icon_name: 'Plant', text: this.$t('idy.homepage.swiperSlider.thirtTitle') },
        { custom_icon: true, custom_icon_name: 'Recycle', text: this.$t('idy.homepage.swiperSlider.fourthTitle') },
        { custom_icon: true, custom_icon_name: 'Clock', text: this.$t('idy.homepage.swiperSlider.fifthTitle') },
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 25,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>
