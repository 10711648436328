<template>
  <b-carousel
    id="carousel-1"
    controls
    indicators
    background="#ababab"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
  >
    <template v-for="img in sliderImages">
      <b-carousel-slide :key="img.id">
        <template #img>
          <img
            class="d-block w-100 slider-image"
            style="aspect-ratio: auto 1024 / 480"
            width="1024"
            :src="require(`@/assets/images/slider/${img.name}`)"
            :alt="img.alt"
          >
        </template>
      </b-carousel-slide>
    </template>

  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      sliderImages: [
        { id: 1, name: 'simpra-slider1.webp', alt: 'Simpra card' },
        { id: 2, name: 'simpra-slider2.webp', alt: 'Simpra card2' },
      ],
    }
  },
}
</script>

<style scoped>
  .slider-image {
    object-fit: cover;
  }
</style>
