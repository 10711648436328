<template>
  <div class="item-features container-bg">
    <div class="row text-center mt-3">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto mt-3 my-md-3">
          <b-card
            :img-src="require('@/assets/images/banner/tasarruf.webp')"
            img-alt="Tasarruf"
            img-top
            no-body
          />
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto mt-3 my-md-3">
          <b-card
            :img-src="require('@/assets/images/banner/yesil_teknoloji.webp')"
            img-alt="Yeşik Teknoloji"
            img-top
            no-body
          />
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto mt-3 my-md-3">
          <b-card
            :img-src="require('@/assets/images/banner/network.webp')"
            img-alt="Network"
            img-top
            no-body
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style scoped>
.container-bg {
  background-color: #F7F7F8 !important;
  margin-right: -2rem;
  margin-left: -2rem;
  margin-bottom: 3.5rem;
}
</style>
