<template>
  <b-row class="justify-content-center">
    <b-col
      cols="12"
      md="11"
    >
      <carousel-slider />
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <!-- <banner-card /> -->
    </b-col>
    <b-col
      cols="12"
      md="11"
    >
      <card-basic-decks />
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <swiper-centered-slides />
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <banner-card-second />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CarouselSlider from '../components/CarouselSlider.vue'
import CardBasicDecks from '../components/CardBasicDecks.vue'
import SwiperCenteredSlides from '../components/SwiperCenteredSlides.vue'
// import BannerCard from '../components/BannerCard.vue'
import BannerCardSecond from '../components/BannerCardSecond.vue'

export default {
  components: {
    BRow,
    BCol,
    CarouselSlider,
    CardBasicDecks,
    SwiperCenteredSlides,
    // BannerCard,
    BannerCardSecond,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
