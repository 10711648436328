<template>
  <b-row>
    <b-col cols="12">
      <b-card-group
        deck
        class="mb-0 my-3"
      >

        <!-- card 1 -->
        <b-card
          :img-src="require('@/assets/images/slider/bottom_banner1.webp')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ $t('idy.homepage.basicDecks.firstTitle') }}</b-card-title>
            <b-card-text>
              {{ $t('idy.homepage.basicDecks.firstText') }}
            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- card 2 -->
        <b-card
          :img-src="require('@/assets/images/slider/bottom_banner2.webp')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ $t('idy.homepage.basicDecks.secondTitle') }}</b-card-title>
            <b-card-text>
              {{ $t('idy.homepage.basicDecks.secondText') }}
            </b-card-text>
          </b-card-body>
        </b-card>

        <!-- card 3 -->
        <b-card
          :img-src="require('@/assets/images/slider/bottom_banner3.webp')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ $t('idy.homepage.basicDecks.thirtTitle') }}</b-card-title>
            <b-card-text>
              {{ $t('idy.homepage.basicDecks.thirtText') }}
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
  },
}
</script>
